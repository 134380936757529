import Termly from 'termly-namespace'


export default function buildPostPayload(args) {
  const {
    acString,
    cookieConsent,
    displayStyle,
    documentVersionID,
    tcString,
    templateVersionID,
    userID,
  } = args

  if ( !Termly.consentOrigin ) {
    return {
      ac_string: acString,
      event_type: displayStyle,
      document_version_id: documentVersionID,
      tc_string: tcString,
      template_version_id: templateVersionID,
      ...cookieConsent,
      ...(!!userID && { uuid: userID }),
    }
  }

  const {
    do_not_sell: ccpaDoNotSell,
    gpc: gpcEnabled,
    ...grantedSelections
  } = cookieConsent

  return {
    ac_string: acString,
    ccpa_do_not_sell: ccpaDoNotSell,
    display_style: displayStyle,
    document_version_id: documentVersionID,
    gpc_enabled: gpcEnabled,
    is_consent_granted_selections: grantedSelections,
    tc_string: tcString,
    template_version_id: templateVersionID,
    visitor: {
      id: userID,
    },
  }
}
